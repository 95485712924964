module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/webapp/nexuschain/ethereum-org-website/src/intl","languages":["en","ar","bg","bn","ca","cs","de","el","es","fa","fi","fr","hi","hr","hu","id","ig","it","ja","ko","lt","ml","nl","nb","pl","pt","pt-br","ro","ru","se","sk","sl","tr","uk","vi","zh","zh-tw"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ethereum.org","short_name":"ethereum.org","start_url":"/en/","background_color":"#fff","theme_color":"#1c1ce1","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b512faa8d4a0b019c123a771b6622aa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://matomo.ethereum.org","siteUrl":"https://ethereum.org","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","trackLoad":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/webapp/nexuschain/ethereum-org-website/node_modules/gatsby-remark-images","id":"acf8c086-257d-5220-b029-8c2b31a352dd","name":"gatsby-remark-images","version":"4.2.0","pluginOptions":{"plugins":[],"backgroundColor":"transparent","maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"enableCustomId":true,"elements":["h1","h2","h3","h4"],"className":"header-anchor"}},{"resolve":"gatsby-remark-images","options":{"backgroundColor":"transparent","maxWidth":1200}},{"resolve":"gatsby-remark-copy-linked-files","options":{"maxWidth":1200}}],"remarkPlugins":[],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/webapp/nexuschain/ethereum-org-website"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ethereum.org"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
