// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-js": () => import("./../../../src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-conditional-dapps-js": () => import("./../../../src/pages-conditional/dapps.js" /* webpackChunkName: "component---src-pages-conditional-dapps-js" */),
  "component---src-pages-conditional-eth-js": () => import("./../../../src/pages-conditional/eth.js" /* webpackChunkName: "component---src-pages-conditional-eth-js" */),
  "component---src-pages-conditional-wallets-index-js": () => import("./../../../src/pages-conditional/wallets/index.js" /* webpackChunkName: "component---src-pages-conditional-wallets-index-js" */),
  "component---src-pages-conditional-what-is-ethereum-js": () => import("./../../../src/pages-conditional/what-is-ethereum.js" /* webpackChunkName: "component---src-pages-conditional-what-is-ethereum-js" */),
  "component---src-pages-developers-index-js": () => import("./../../../src/pages/developers/index.js" /* webpackChunkName: "component---src-pages-developers-index-js" */),
  "component---src-pages-developers-learning-tools-js": () => import("./../../../src/pages/developers/learning-tools.js" /* webpackChunkName: "component---src-pages-developers-learning-tools-js" */),
  "component---src-pages-developers-local-environment-js": () => import("./../../../src/pages/developers/local-environment.js" /* webpackChunkName: "component---src-pages-developers-local-environment-js" */),
  "component---src-pages-developers-tutorials-js": () => import("./../../../src/pages/developers/tutorials.js" /* webpackChunkName: "component---src-pages-developers-tutorials-js" */),
  "component---src-pages-eth-2-deposit-contract-js": () => import("./../../../src/pages/eth2/deposit-contract.js" /* webpackChunkName: "component---src-pages-eth-2-deposit-contract-js" */),
  "component---src-pages-eth-2-get-involved-bug-bounty-js": () => import("./../../../src/pages/eth2/get-involved/bug-bounty.js" /* webpackChunkName: "component---src-pages-eth-2-get-involved-bug-bounty-js" */),
  "component---src-pages-eth-2-get-involved-index-js": () => import("./../../../src/pages/eth2/get-involved/index.js" /* webpackChunkName: "component---src-pages-eth-2-get-involved-index-js" */),
  "component---src-pages-eth-2-index-js": () => import("./../../../src/pages/eth2/index.js" /* webpackChunkName: "component---src-pages-eth-2-index-js" */),
  "component---src-pages-eth-2-staking-js": () => import("./../../../src/pages/eth2/staking.js" /* webpackChunkName: "component---src-pages-eth-2-staking-js" */),
  "component---src-pages-eth-2-vision-js": () => import("./../../../src/pages/eth2/vision.js" /* webpackChunkName: "component---src-pages-eth-2-vision-js" */),
  "component---src-pages-get-eth-js": () => import("./../../../src/pages/get-eth.js" /* webpackChunkName: "component---src-pages-get-eth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-stablecoins-js": () => import("./../../../src/pages/stablecoins.js" /* webpackChunkName: "component---src-pages-stablecoins-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-wallets-find-wallet-js": () => import("./../../../src/pages/wallets/find-wallet.js" /* webpackChunkName: "component---src-pages-wallets-find-wallet-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-templates-eth-2-js": () => import("./../../../src/templates/eth2.js" /* webpackChunkName: "component---src-templates-eth-2-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */),
  "component---src-templates-tutorial-js": () => import("./../../../src/templates/tutorial.js" /* webpackChunkName: "component---src-templates-tutorial-js" */),
  "component---src-templates-use-cases-js": () => import("./../../../src/templates/use-cases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

